import { getUrl } from '../../config';
import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom"; // Ajout de l'importation
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";



import { Badge, Grid, Avatar, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useAuth();
  const uploadedImage = localStorage.getItem('uploadedImage');
  const userId = localStorage.getItem('userId');
  const profilId = localStorage.getItem('profilId');

  const getProfil = async () => {
    try {
      const response = await axios.get(getUrl() + `/api/profiles/getProfilByUser/` + userId)
      if (response.data.status === 'success') {
        localStorage.setItem("profilId", response.data.data.id)
      } else {
        console.error('Erreur lors de la récupération du profil: ', response.data.message);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du profil :", error);
    } finally {
    }
  };

  React.useEffect(
    () => {
      getProfil();
    },
    []
  )


  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && <Avatar alt={user.username} src={uploadedImage || user.avatar} />} { }
          </FooterBadge>
        </Grid>
        <Grid item>
          {!!user && (
            <Link to={"/profile/" + profilId} style={{ textDecoration: 'none' }}>
              <FooterText variant="body2">{user.firstname} {user.lastname}</FooterText>
            </Link>
          )}
          <FooterSubText variant="caption">Utilisateur Ylinar</FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};
export default SidebarFooter;
