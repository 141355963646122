import { getUrl } from '../../config';
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";
import { MessageSquare } from "react-feather";
import { useNavigate } from 'react-router-dom';

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

function Message({ title, description, image }) {
  return (
    <ListItem divider component={Link} to="#">
      <ListItemAvatar>
        <Avatar src={image} alt="Avatar" />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarMessagesDropdown() {
  const ref = useRef(null);

  const navigate = useNavigate();

  const MesOnClick = () => {
    navigate("/file/messagerie");
};

  return (
    <React.Fragment>
      <Tooltip title="Messages">
        <IconButton color="inherit" ref={ref} size="large" onClick={MesOnClick}>
            <MessageSquare />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarMessagesDropdown;
