import { getUrl, setUrl } from '../../config';
import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const languageOptions = {
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
    id: "0",
  },
  fr: {
    icon: "/static/img/flags/fr.png",
    name: "French",
    id: "1"
  },
};

function NavbarLanguagesDropdown() {
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { t } = useTranslation();

  const selectedLanguage = languageOptions[i18n.language];

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLanguageChange = async (language) => {
    try {
      let id = languageOptions[language].id
      await axios.post(getUrl() + "/api/user/setLanguage", { id });
      i18n.changeLanguage(language);

      if (id == 1) {
        setUrl('https://api.clavier.ylinar.fr')
        navigate("/");
      } else {
        setUrl('https://api.jay.ylinar.fr')
        navigate("/");
      }

    }
    catch (error) {
      console.error("Erreur lors de la définition de la langue :", error);
    }
    closeMenu();
  };

  return (
    <React.Fragment>
      <Tooltip title={t("Languages")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {t(languageOptions[language].name)}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
