import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
import { LicenseInfo } from '@mui/x-license-pro';
import fr from 'date-fns/locale/fr';

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) { //Old 11b45582150446055ba2cc09ac03a26fTz03MTg1MSxFPTE3MjI1MDU3OTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y
  //New 0c8a9ccfa7f13b54aeb54f83b00d5523Tz0xMDU4OTcsRT0xNzY4NDM1MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI
  LicenseInfo.setLicenseKey('0c8a9ccfa7f13b54aeb54f83b00d5523Tz0xMDU4OTcsRT0xNzY4NDM1MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI');

  const content = useRoutes(routes);

  const { theme } = useTheme();
  

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | YLinar"
          defaultTitle="YLinar - ERP Dashboard"
        />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AuthProvider>{content}</AuthProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
