import { getUrl } from '../../config';
import { setUrl } from '../../config';
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Popover, MenuItem, ListItemIcon } from "@mui/material";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const erpOptions = {
  Clavier: {
    logo: "http://127.0.0.1:3333/images/upload/society/" + "clavier.jpeg",
    name: "Clavier",
    url: 'https://api.clavier.ylinar.fr'
  },
  Jay: {
    logo: "http://127.0.0.1:3333/images/upload/society/" + "jay.jpeg",
    name: "Jay",
    url: 'https://api.jay.ylinar.fr'
  },
  Cybax: {
    logo: "http://127.0.0.1:3333/images/upload/society/" + "cybax.jpeg",
    name: "Cybax",
    url: 'http://cybax.ylinar.fr:3333'
  },
  Local: {
    logo: "http://127.0.0.1:3333/images/upload/society/" + "logo.png",
    name: "Local",
    url: 'http://127.0.0.1:3333'
  }
};

function SignIn() {
  const { signIn } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedErp, setSelectedErp] = useState(null);
  const [erpError, setErpError] = useState(null);
  
  useEffect(() => {
    const storedErp = localStorage.getItem('selectedErp');
    if (storedErp) {
      setSelectedErp(storedErp);
    }
  }, []);

  const handleErpChange = async (erp) => {
    try {
        setUrl(erpOptions[erp].url);
        setSelectedErp(erp);
        setErpError(null);
        navigate("/auth/sign-in");
    } catch (error) {
        console.error("Erreur lors du changement d'erp :", error);
    }
    setAnchorEl(null);
};

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required("Utilisateur est requis"),
        password: Yup.string().max(255).required("Mot de passe est requis"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (!selectedErp) {
          setErpError("Veuillez sélectionner une entreprise avant de vous connecter.");
          return; // Empêcher la soumission si aucune entreprise n'est sélectionnée
        }

        try {
          await signIn(values.username, values.password);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: "Identifiant Incorrect" });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {erpError && (
            <Alert mt={2} mb={3} severity="warning">
              {erpError}
            </Alert>
          )}
          <TextField
            type="username"
            name="username"
            label="Utilisateur"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Mot de Passe"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ mb: 4 }}
          >
            Connexion
          </Button>

          {/* Sélecteur d'ERP */}
          <div>
            <Button
              aria-controls="erp-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              fullWidth
              variant="outlined"
            >
              {selectedErp ? `Entreprise: ${erpOptions[selectedErp].name}` : "Choisir une entreprise"}
            </Button>
            <Popover
              id="erp-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: '30%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: '8px 0',
                },
              }}
            >
              {Object.keys(erpOptions).map((erp) => (
                <MenuItem key={erp} onClick={() => handleErpChange(erp)}>
                  <ListItemIcon>
                    <img
                      src={erpOptions[erp].logo}
                      alt={erpOptions[erp].name}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                  </ListItemIcon>
                  {erpOptions[erp].name}
                </MenuItem>
              ))}
            </Popover>
          </div>
        </form>
      )}
    </Formik >
  );
};

export default SignIn;
